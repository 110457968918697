import { createSlice } from '@reduxjs/toolkit';

// -------------------------------------
// Store
// -------------------------------------

export const reducerName = 'settings';
export const initialState = {
	enableLogs: false,
};
export const settingsSlice = createSlice({
	name: reducerName,
	initialState,
	reducers: {
		cleanSettings: () => initialState,
		setSettings: (state, action) => ({
			...state,
			...action.payload,
		}),
	},
});

// -------------------------------------
// Selectors
// -------------------------------------

// -------------------------------------
// Exports
// -------------------------------------

export const { cleanSettings, setSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
