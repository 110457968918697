import React, { Suspense, lazy } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Banner from './banner/Banner';
import { ROUTES } from '../utils/utils';

// -------------------------------------
// Lazy Imports
// -------------------------------------

const Founders = lazy(() => import('./founders/Founders'));

// -------------------------------------
// Component
// -------------------------------------

function Main() {
	return (
		<HashRouter>
			<Routes>
				<Route path={'/'} element={<Banner />} />
				<Route
					path={`/${ROUTES.founders}/*`}
					element={
						<Suspense fallback={<div>Loading</div>}>
							<Founders />
						</Suspense>
					}
				/>
				<Route path={'*'} element={<div>Not found</div>} />
			</Routes>
		</HashRouter>
	);
}

export default Main;
