/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Image, View, useBreakpointValue } from "@aws-amplify/ui-react";
import HeroLayout3 from "./HeroLayout3";
export default function HeroLayout2(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      variantValues: { breakpoint: "large" },
      overrides: { image: {}, HeroLayout3: {}, HeroLayout2: {} },
    },
    {
      variantValues: { breakpoint: "small" },
      overrides: {
        image: {
          width: "390px",
          height: "540px",
          top: "calc(50% - 270px - 0px)",
          left: "calc(50% - 195px - 0px)",
        },
        HeroLayout3: {
          top: "calc(50% - 270px - 0px)",
          left: "calc(50% - 195px - 0px)",
          padding: "240px 10px 240px 10px",
          breakpoint: "small",
        },
        HeroLayout2: { width: "390px", height: "540px" },
      },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <View
      width="1440px"
      height="860px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "HeroLayout2")}
      {...rest}
    >
      <Image
        width="1440px"
        height="860px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="calc(50% - 430px - 0px)"
        left="calc(50% - 720px - 0px)"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        {...getOverrideProps(overrides, "image")}
      ></Image>
      <HeroLayout3
        display="flex"
        gap="10px"
        direction="column"
        width="1440px"
        height="860px"
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        position="absolute"
        top="calc(50% - 430px - 0px)"
        left="calc(50% - 720px - 0px)"
        padding="160px 240px 160px 240px"
        backgroundColor="rgba(0,0,0,0.5)"
        breakpoint="large"
        {...getOverrideProps(overrides, "HeroLayout3")}
      ></HeroLayout3>
    </View>
  );
}
