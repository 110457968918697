import { createInstance } from 'i18next';
// APP
import appEn from '../localization/en.json';
import appEs from '../localization/es.json';

// -------------------------------------
// Main
// -------------------------------------

const resources = {
	en: {
		app: appEn,
	},
	es: {
		app: appEs,
	},
};
const { isTesting = false, lng = 'es' } = Object.fromEntries(
	new URLSearchParams(window.location.search).entries()
);

// -------------------------------------
// Main
// -------------------------------------

const i18nInstance = createInstance();
i18nInstance.init({
	resources,
	lng: lng,
	fallbackLng: 'es',
	debug: isTesting === 'true' || isTesting === true,
	interpolation: {
		escapeValue: false, // react already safes from xss
	},
});

// -------------------------------------
// Main
// -------------------------------------
export default i18nInstance;
export const t = i18nInstance.t.bind(i18nInstance);
