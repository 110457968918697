import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { store as reduxStore } from './redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import i18n from './localization/i18next';
import Main from './pages/Main';

function App() {
	return (
		<ReduxProvider store={reduxStore}>
			<I18nextProvider i18n={i18n}>
				<Main />
			</I18nextProvider>
		</ReduxProvider>
	);
}

export default App;
